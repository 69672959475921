.video-js {
    width: 670px;
    height: 670px;
    /* z-index: 2; */
}

.vjs-playlist {
    position: absolute;
    top: 0;
    right: -230px;
    z-index: 1;
    width: 230px;
    height: 670px;
}

/* .vjs-matrix .vjs-big-play-button {
    border-color: #00ff00;
} */

.video-js .vjs-big-play-button {
    left: 43%; 
    top: 43%;
    border-color: #00ff00;
}

.vjs-playlist .vjs-playlist-thumbnail-placeholder {
    /* background-color: rgb(176, 196, 223); */
    height: 60px;
}

/* .vjs-playlist {
    background-color: rgb(243, 241, 239);
} */

.vjs-icon-next-item::before , .vjs-icon-previous-item::before {
    font-size: 2em
}

.vjs-playlist-now-playing-text {
    font-weight: bold;
    /* color: blue */
}

.vjs-mouse.vjs-playlist {
    font-size: 15px;
}