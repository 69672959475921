.App {
    font-family: "RedHatText";
    width: 100%;
    height: 100%;
}

#logo {
    position: absolute;
    height: 110px;
    /* background-position: center; */
}